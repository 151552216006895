import React from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Row,
    Col, 
    Modal   
  } from 'react-bootstrap';

const ModalHeader = (props) => {
    return (
        <Modal.Header closeButton className={c.modal_background}>
           <Container>                         
            <Row className={c.modal__form}>
              <Col>
               <h4 className={c.modal__form}>
                 {props.title}
               </h4>
              </Col>
            </Row>
           </Container> 
          </Modal.Header>
    )
}

export default ModalHeader
