import React, { useState, useEffect, useRef }  from 'react'
import {
    Container,     
    FormControl,
    Button,
    Popover,
    OverlayTrigger
  } from 'react-bootstrap';
import c from './vault.module.css';
import { setCookie } from '../Functions';
import PinInput from './PinInput';
import { EyeOpenIcon, EyeClosedIcon } from '../Components/Icons';


const EnterKeyValue = ({ setKeyValuePin, sjcl, setTriger, triger }) => {

    const inputRef0 = useRef();
    const inputRef1 = useRef();
    const inputRef7 = useRef();
    const inputRef8 = useRef();

    useEffect(() => {           
        inputRef0.current.focus();
     }, []);
 
    const [valueKey, setValueKey] = useState('')
    const [valuePIN, setValuePIN] = useState(null)
    const [hiddenValue, setHiddenValue] = useState(true)
    

    const handleChange = (e) => {
      const value = e.target.value;      
        setValueKey(value);
    };

   

//     useEffect(() => {
// console.log(valueKey)
// // eslint-disable-next-line
//     }, [hiddenValue])

    const [triggerClearForm, setTriggerClearForm] = useState(false)
    const clearForm = () => {
      setTriggerClearForm(!triggerClearForm)
      setValueKey('')    
      inputRef0.current.focus();
    }
  
    const saveData = () => {
      if(valueKey && valuePIN) {      
        const encryptedvalueKey = sjcl.encrypt(valuePIN, valueKey).toString();
          
        setCookie('keyValue', encryptedvalueKey, 7300)
      //  Cookies.set('keyValue', encryptedvalueKey, { expires: 7, secure: true });
        
        setKeyValuePin(valuePIN)
      }
      setTriger(!triger)
      //window.location.reload();
    }
  
    const shiftFocus = () => {
      inputRef1.current.focus();
    }
    const shiftFocusCncl = () => {
      inputRef8.current.focus();
    }

    const popover1 = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">ключ шифрування даних</Popover.Title>
        <Popover.Content>
               Надійний ключ шифрування складається щонайменше з 16 символів, 
               включно з літерами нижнього та верхнього регістрів, цифрами та спеціальними знаками. 
               <strong> БУДЬТЕ ОБЕРЕЖНІ — якщо ви загубите ключ шифрування даних, ваші дані не можна буде прочитати.
               Такий ключ не можна відновити.</strong>  Ключ шифрування даних буде автоматично видалено зі сховища браузера 
               через 3 доби після його введення.
        </Popover.Content>
      </Popover>
    );
    const popover2 = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">PIN ключа шифрування</Popover.Title>
        <Popover.Content>
              Пін ключа шифрування потрібен для безпечного зберігання Вашого ключа шифрування в пам&#39;яті 
              браузера у зашифрованій формі. Цей пін можна відновити разом із перезаписом ключа шифрування. 
              Ключ шифрування даних буде автоматично видалено зі сховища браузера через 3 доби після його введення.
        </Popover.Content>
      </Popover>
    );
    
  return (
    <Container className={`my-5 ${c.modal__form}`}>
      <div className={`mt-5 pt-3`}>
              <h4 className={`${c.c}`}>
                Введить ключ <br/>шифрування даних
              </h4> 
           
             <div className={c.input_box}>
              <div onClick={() => setHiddenValue(!hiddenValue)} className={c.switch}>
                {hiddenValue ? EyeOpenIcon(29, '#111827') : EyeClosedIcon(29, '#111827')} 
              </div>
               <FormControl 
                 value={valueKey} 
                 onChange={(e) =>  handleChange(e)} 
                 type={hiddenValue ? 'password' : 'text'}
                 ref={inputRef0}
                 onBlur={shiftFocus}
               /> <br/>
                <OverlayTrigger trigger="click" placement="top" overlay={popover1}>
                  <Button variant="outlined">Що це?</Button>
                </OverlayTrigger>               
             </div>
           

              <h4 className={`${c.c}`}>
               Введить P I N<br/> ключа шифрування
              </h4> 
           
             <div className={`mb-0`}>
               <PinInput 
                 setValuePIN={setValuePIN} 
                 valuePIN={valuePIN}
                 triggerClearForm={triggerClearForm}
                 inputRef7={inputRef7} 
                 inputRef0={inputRef0}
                 inputRef1={inputRef1}               
               />
                <OverlayTrigger trigger="click" placement="top" overlay={popover2}>
                  <Button variant="outlined">Що це?</Button>
                </OverlayTrigger>
             </div>

       
         <div className={c.footer_btn}>
            <Button onClick={() => clearForm()} variant="secondary" ref={inputRef8} onBlur={() => inputRef0.current.focus()}>Скасувати</Button>
            <Button variant="success" onClick={saveData} disabled={!valueKey || !valuePIN} ref={inputRef7} onBlur={() => shiftFocusCncl()}>
              Зберегти данні
            </Button>
         </div>
     </div>
           

    </Container>
  )
}

export default EnterKeyValue