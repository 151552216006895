import { useState, useEffect } from 'react';
import firebase from "../firebase";

 const useFirestore = (collection) => {
   
  const [rides, setRides] = useState([]);
  const [certs, setCerts] = useState([]);
  const [spendings, setSpendings] = useState([]);
  const [totals, setTotals] = useState([]);
  const [shiftTotals, setShiftTotals] = useState([]);
  const [partslist, setPartslist] = useState([]);
  const [jobslist, setJobslist] = useState([]);
  const [partsRequestlist, setPartsRequestlist] = useState([]);
  const [jobsRequestlist, setJobsRequestlist] = useState([]);
  const [oillist, setOillist] = useState([]);
  const [rentlist, setrentlist] = useState([]);
  const [rentlistItemDocs, setrentlistItemDocs] = useState([]);
  const [carDocs, setCarDocs] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [crypto, setCrypto] = useState([]);
  const [privatCars, setPrivatCars] = useState([]);
  const [scedule, setScedule] = useState([]);
  const [partsInStock, setpartsInStock] = useState([]);
  const [partsInstalledFromStock, setPartsInstalledFromStock] = useState([]);
  const [percTaxiOps, setPercTaxiOps] = useState([]);
  const [newData, setNewData] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  

  useEffect(() => {

    setLoadingData(true);

  const unsub = firebase.firestore().collection(collection)
    .orderBy('sort', 'desc')
    .onSnapshot((snap) => {
        let docs = [];
        snap.forEach(doc => {
            docs.push({id : doc.id, ...doc.data()});
        });
        setRides(docs);
        setSpendings(docs);
        setTotals(docs);
        setPartslist(docs);
        setJobslist(docs);
        setPartsRequestlist(docs);
        setJobsRequestlist(docs);
        setOillist(docs);
        setShiftTotals(docs);
        setrentlist(docs);
        setrentlistItemDocs(docs);
        setCarDocs(docs);
        setContactsList(docs);
        setFleet(docs);
        setCrypto(docs);
        setPrivatCars(docs);
        setScedule(docs);
        setpartsInStock(docs);
        setPartsInstalledFromStock(docs);    
        setPercTaxiOps(docs);
        setNewData(docs);
        setCerts(docs);
         setLoadingData(false);
      });

     

    return () => unsub();
  }, [collection]);
  return { 
    
    rides, 
    spendings, 
    totals, 
    shiftTotals, 
    partslist, 
    jobslist,
    partsRequestlist, 
    jobsRequestlist,
    oillist, 
    rentlist, 
    carDocs, 
    rentlistItemDocs,
    contactsList, 
    fleet,
    crypto,
    privatCars,
    scedule,
    partsInStock,
    percTaxiOps,
    partsInstalledFromStock,
    newData,
    loadingData,
    certs, 
    setCerts
  };
};
export default useFirestore;
 
// useEffect(() => {
//     firebase.collection(collection)
//      .onSnapshot((snap) => {
//          let documents = [];
//          snap.forEach( doc => {
//             documents.push({id : doc.id, ...doc.data()});
         
//         });
//         setDocs(documents); 
//      }, [collection]);

    
// });