import React,  { useEffect, useState, useContext } from 'react';
import c from '../screens/Screens.module.css';
import classes from '../Components/Screen.module.css';
import { Table } from 'react-bootstrap';
import PageTitle from '../Components/PageTitle';
import NavBarInit from '../Components/NavBarInit';
import useFirestore from "../Hooks/useFirestore";
import { ContractContext } from '../Context/ContractContext';
import _ from "lodash";
import { 
    FileTextIcon,
    dateIcon2,
    BasketIcon,
    BankIcon,
    FileNumberIcon,
    MoistureIcon,
    DropletIcon,
    PoundIcon,
    dollarIcon, 
    smileIcon,
    ShieldIcon,
    DatabaseIcon, 
} from '../Components/Icons';
import AddEditRecord from './AddEditRecord';
import SpinnerBig from '../Components/SpinnerBig';
import AddCert from './AddCert';
import CascTypes from './CascTypes';

const Whisky = () => {


const collection = 'whisky';
const {crypto} = useFirestore(collection);
const {cryptoList, setСryptoList} = useContext(ContractContext);




 useEffect(() => {
   if (crypto.length > 0) {
    setСryptoList(crypto);  
   } 
   // eslint-disable-next-line 
 }, [crypto]);   





const style = {
color: "#FFFFFF",
border: "none"
};

const [initSum, setInitSum] = useState('');
const calculateSum = arg => {
    let result = _.sum(
       arg.map((doc) => (+doc.priceUSD))); ////////////////////// Процент оператора
       // eslint-disable-next-line
    return result, 
    setInitSum(result);
 };

 

useEffect(() => {
 if(cryptoList.length > 0) {     
     calculateSum(cryptoList);   
 }
}, [cryptoList]);





    return (
        <>

      <div className={`${classes.container} ${classes.home}`}>
           <PageTitle 
         title={`S&N | WHISKY TRACKER`}        
        />
        <NavBarInit 
          route={'/'}
        />
         
        <div className={c.col_container}> 
          <div className={c.col_side_limits}>
            {cryptoList && cryptoList.length > 0 ? (
              <>


         <div className={` ${c.button_container}`}>
            <div className={`mt-3 ${c.tabletop__btn__left}`}>
              <CascTypes />
            </div>
            <div className={` ${c.goback__btn__cont}`}>
                <AddEditRecord 
                // eslint-disable-next-line
                    style={"outline-dark"}
                    title={'Додати Запис'}                   
                    collection={'whisky'}
                    date={true}
                />
            </div>
         </div>
            <Table responsive striped bordered hover variant="dark" className={`mt-3 ${c.t_row_rides}`}> 
                <thead  >
                    <th colSpan="13"> 
                    <h5 style={style}>Інвестиції у WHISKY</h5>                 
                    </th>
                </thead>
                <thead >
                      <th><abbr title="Дата придбання">{dateIcon2()}</abbr></th>
                      <th ><abbr title="Постачальник">{BasketIcon(28)}</abbr></th>
                      <th ><abbr title="Distillery">{BankIcon(28)}</abbr></th>
                      <th><abbr title="Cask Number">{FileNumberIcon()}</abbr></th> 
                      <th ><abbr title="AYS (Age Your Spirit)">{dateIcon2()}</abbr></th>  
                      <th><abbr title="Spirit Name">{DropletIcon()}</abbr></th>
                      <th><abbr title="Stored @">{DatabaseIcon()}</abbr></th>                         
                      <th><abbr title="Cask Type">{MoistureIcon()}</abbr></th> 
                      <th ><abbr title="Price Paied GBP">{PoundIcon()}</abbr></th>
                      <th ><abbr title="Price Paied USD">{dollarIcon()}</abbr></th>
                      <th><abbr title="Cask is registred to">{smileIcon()}</abbr></th>
                      <th><abbr title="Insured till">{ShieldIcon()}</abbr></th>
                      <th><abbr title="Certificate">{FileTextIcon()}</abbr></th>
                    </thead>
                    <tbody>                     
                        {cryptoList?.map((doc) => 
                        <tr key={doc.id}>
                            <td>
                                {doc.dateDay}<br/>
                                {doc.dateMonth}<br/>
                                {doc.dateYear}
                            </td> 
                          <td class="pt-5">{doc.soldBy} </td>
                          <td class="pt-5">{doc.distillery} </td>
                          <td class="pt-5">{doc.caskNum} </td>
                            <td>
                                {doc.AYSdateDay}<br/>
                                {doc.AYSdateMonth}<br/>
                                {doc.AYSdateYear}
                            </td> 
                          <td class="pt-5">{doc.spiritName} </td>
                          <td class="pt-5">{doc.storedAt} </td>
                          <td class="pt-5">{doc.caskType} </td>
                          <td class="pt-5">{doc.priceGBP} </td>
                          <td class="pt-5">{doc.priceUSD} </td>
                          <td class="pt-5">{doc.owner} </td>
                          <td class="pt-5">{doc.insuredTill} </td>
                        <td className="pt-5">
                            <AddCert doc={doc}/> 
                        </td>
                     </tr>
                        )}
                       
                    </tbody>  
                    <thead>
                      <tr>
                            <th colSpan="9">
                                Усього інвестовано $
                            </th>
                            <td colSpan="4">{initSum} </td>                           
                      </tr> 
                    </thead>           
             </Table>   
              </>
            ) : <SpinnerBig /> }          
         </div> 
        </div>
      </div>
      </>
    )
}

export default Whisky
