import React, { useState }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert 
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ModalHeader from '../Components/ModalHeader';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import DatePicker from '../Components/DatePicker';

import { addItemNoRef } from '../Functions';
import AddBtn from '../Components/AddBtn';

const AddEditRecord = (props) => {
 
const [showModalForm, setShowModalForm] = useState(false);

///////////////////////////// Объявляем переменные
  const [dateEmpty, setDateEmpty] = useState(false);
  const [notice, setNotice] = useState(false);
  const [dateDay, setDateDay] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateYear, setDateYear] = useState(''); 
  const [sort, setSort] = useState('') 
  const [sortAYS, setSortAYS] = useState('') 
  const [soldBy, setSoldBy] = useState('')
  const [distillery, setDistillery] = useState('')
  const [caskNum, setCaskNum] = useState('')
  const [AYSdateDay, setAYSdateDay] = useState('')
  const [AYSdateMonth, setAYSdateMonth] = useState('')
  const [AYSdateYear, setAYSdateYear] = useState('')
  const [spiritName, setSpiritName] = useState('')
  const [storedAt, setStoredAt] = useState('')
  const [caskType, setCaskType] = useState('')         
  const [priceGBP, setPriceGBP] = useState('')
  const [priceUSD, setPriceUSD] = useState('')
  const [owner, setOwner] = useState('')
  const [insuredTill, setInsuredTill] = useState('')         
  const [certCollection, setCertCollection] = useState('')    





////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
   
    setSoldBy('')
    setDistillery('')
    setCaskNum('')
    setAYSdateDay('')
    setAYSdateMonth('')
    setAYSdateYear('')
    setSpiritName('')
    setStoredAt('')
    setCaskType('')         
    setPriceGBP('')
    setPriceUSD('')
    setOwner('')
    setInsuredTill('')         
    setCertCollection('')
    setShowModalForm(false);
  };

   
///////////////////////////////// Добавим Алерт о добавлении Траты
 const [alertSuccess, setAlertSuccess] = useState(false); 


const dataAdded = () => {
   setAlertSuccess(true);
   hideModal()
   setTimeout(() => setAlertSuccess(false), 2000 );
       
};
 ////////// Проверим заполненность формы 
      const checkForm = (event) => { 
         addNewSpend(event);
      };

      function addNewSpend (event) {
        event.preventDefault();
  ///////////////////////////// Формируем данные поездки
        const data = { 
            dateDay,
            dateMonth, 
            dateYear,  
            sort,
            soldBy,
            distillery,
            caskNum,
            AYSdateDay,
            AYSdateMonth,
            AYSdateYear,
            spiritName,
            storedAt,
            caskType,           
            priceGBP,
            priceUSD,
            owner,
            insuredTill,           
                 
        }; 
  /////////////////////////// Отправлем форму
   addItemNoRef(data, props.collection);
  ////////////////// Обнуляем поля формы
         
       dataAdded();
    }

    return (
        <>
         <div className='mt-3'> 
           <AddBtn 
            edit={props.edit}
            variant={props.style}
            action={() => setShowModalForm(true)}
            folderIcon={false}
            text={props.title ? props.title : 'Добавить запись'}
           />
         </div>
            
            <Modal
           
            show={showModalForm}
            onHide={hideModal}          
            aria-labelledby="example-custom-modal-styling-title"
            size="md"
            >
          <ModalHeader 
            title={alertSuccess ? ('Запись Добавлена') : ('Добавьте Запись')}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
               {!alertSuccess && (
                 <>
                 
                 <DatePicker 
                    header={'Дата придбання'}
                    setDateDay={setDateDay}
                    setDateMonth={setDateMonth}
                    setDateYear={setDateYear}
                    setSortDate={setSort}
                    setDateEmpty={setDateEmpty}
                    setNotice={setNotice}
                    alertHeader={'Оберить дату придбання'}
                    dateEmpty={dateEmpty}
                  />
                 <ModalFormline header={'Постачальник'}>                     
                      <FormControl value={soldBy} onChange={e => setSoldBy(e.target.value)} type="text"/>                    
                 </ModalFormline>

                 <ModalFormline header={'Distillery'}>                     
                      <FormControl value={distillery} onChange={e => setDistillery(e.target.value)} type="text"/>                    
                 </ModalFormline>

                 <ModalFormline header={'Cask Number'}>                          
                     <FormControl value={caskNum} onChange={e => setCaskNum(e.target.value)} type="text" />                       
                 </ModalFormline>                

                 <DatePicker 
                    header={'AYS'}
                    setDateDay={setAYSdateDay}
                    setDateMonth={setAYSdateMonth}
                    setDateYear={setAYSdateYear}
                    setSortDate={setSortAYS}
                    setDateEmpty={setDateEmpty}
                    setNotice={setNotice}
                    alertHeader={'Оберить дату розливу'}
                    dateEmpty={dateEmpty}
                  />

                  <ModalFormline header={'Spirit Name'}>                     
                      <FormControl value={spiritName} onChange={e => setSpiritName(e.target.value)} type="text"/>                    
                  </ModalFormline>

                 <ModalFormline header={'Storred at'}>                     
                      <FormControl value={storedAt} onChange={e => setStoredAt(e.target.value)} type="text"/>                    
                 </ModalFormline>

                 <ModalFormline header={'Cask Type'}>                     
                      <FormControl value={caskType} onChange={e => setCaskType(e.target.value)} type="text" />                    
                 </ModalFormline>

                 <ModalFormline header={'Price GBP'}>                     
                      <FormControl value={priceGBP} onChange={e => setPriceGBP(e.target.value)} type="text" />                    
                 </ModalFormline>

                 <ModalFormline header={'Price USD'}>                     
                      <FormControl value={priceUSD} onChange={e => setPriceUSD(e.target.value)} type="text" />                    
                 </ModalFormline>

                 <ModalFormline header={'Owner'}>                     
                      <FormControl value={owner} onChange={e => setOwner(e.target.value)} type="text" />                    
                 </ModalFormline>

                 <ModalFormline header={'Insured Till'}>                     
                      <FormControl value={insuredTill} onChange={e => setInsuredTill(e.target.value)} type="text" />                    
                 </ModalFormline>
                

                 <AlertDangerTriangle 
                    notice={notice}
                    alertHeader={'Пожалуйста, заполните необходимые данные'}
                  /> 
                   </>
               )}

                 {alertSuccess &&
                 <Alert variant='success' >
                  <span className={c.alert}>
                    Данные сохранены
                  </span>
                 </Alert> }
             </Container>
           </Modal.Body>
           <Modal.Footer>
           {!alertSuccess && (
             <>               
              <Button onClick={hideModal} variant="secondary">Отменить</Button>
              <Button variant="success" onClick={checkForm}>Сохранить данные</Button>
             </>
              )} 
            </Modal.Footer>
         </Modal>  
        </>
    )
}

export default AddEditRecord
