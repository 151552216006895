import React, { useState } from 'react';
import c from '../Components/Screen.module.css';
import { Alert, Form, Container } from 'react-bootstrap';
import ProgBarMulty from './ProgBarMulty';

const ImgPickerMultiple = ({ setFilesData, setLoading, onFileUrl, docId }) => {
  const [files, setFiles] = useState([]);
  const [alert, setAlert] = useState('');
  const [uploadedUrls, setUploadedUrls] = useState([]); // Track uploaded URLs locally

  const fileSelected = (e) => {
    setAlert('');
    const selectedFiles = Array.from(e.target.files);
    const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
    const validFiles = selectedFiles.filter((file) => validTypes.includes(file.type));

    if (validFiles.length === 0) {
      setAlert('Обрано не припустимий тип файлу. Оберить фото або PDF.');
      return;
    }

    setFiles(validFiles);
    setFilesData(validFiles);
    setLoading(true);
    setUploadedUrls([]); // Reset URLs when new files are selected
  };

  // New function to handle completed uploads
  const handleUploadComplete = (url) => {
    setUploadedUrls(prev => {
      const newUrls = [...prev, url];
      onFileUrl(files[0], url); // Update parent component with the first file
      return newUrls;
    });
  };

  return (
    <Container fluid>
      <Form>
        {alert && (
          <Alert variant="danger">
            <h5 className={c.alert}>{alert}</h5>
          </Alert>
        )}
        <Form.Group>
          <Form.File
            className={c.alert}
            type="file"
            multiple
            label="Оберить Файли"
            onChange={fileSelected}
            lang="uk"
            custom
          />
        </Form.Group>
        {files.length > 0 && (
          <>
            <Alert variant="info">
              <span className={c.alert}>
                Обрано файлів: {files.map((file) => file.name).join(', ')}
              </span>
            </Alert>
            {files.map((file, index) => (
              <ProgBarMulty
                key={index}
                file={file}
                setFiles={setFiles}
                setLoading={setLoading}
                onUploadComplete={(file, url) => onFileUrl(file, url)}
                docId={docId}
              />
            ))}
          </>
        )}
      </Form>
    </Container>
  );
};

export default ImgPickerMultiple;
