import React, { useState } from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,
    Row,
    Col 
} from 'react-bootstrap';
//import ModalFormline from '../screens/ModalFormline';
import ModalHeader from '../Components/ModalHeader';
import { HandIndexThumbIcon, PlusCircle2Icon } from '../Components/Icons';
import ImgPickerMultiple from '../Components/ImgPickerMultiple';
import { addItemNoRef } from '../Functions';
import useSubcollection from "../Hooks/useSubcollection";
import DeleteConfirmForm from '../Components/DeleteConfirmForm';

const AddCert = ( {doc} ) => {
    const [showModalForm, setShowModalForm] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileUrls, setFileUrls] = useState({});

                       
    const collection = `whisky/${doc.id}/certificates`;
    const { documents: certificates } = useSubcollection(collection);
    
    // Add debug logging
    console.log('Certificates:', certificates);

    const hideModal = () => {   
        setShowModalForm(false);
        setSelectedFiles([]);
        setFileUrls({});
    };

    const handleFileAdd = (files) => {
        setSelectedFiles(files);
    };

    const handleFileUrl = (file, url) => {
        setFileUrls(prev => ({
            ...prev,
            [file.name]: url
        }));
    };

    const handleSave = async () => {
        if (!doc?.id) {
            console.error("Parent document ID is missing.");
            return;
        }
    
        setLoading(true);
    
        try {
            // Save metadata for each uploaded file
            for (const file of selectedFiles) {
                const fileUrl = fileUrls[file.name];
                
                if (!fileUrl) {
                    console.error(`No URL found for file: ${file.name}`);
                    continue;
                }

                const fileData = {
                    fileName: file.name,
                    fileType: file.type,
                    uploadedAt: new Date(),
                    downloadURL: fileUrl,
                    path: `whisky-certificates/${doc.id}/${file.name}`
                };
                
                await addItemNoRef(fileData, collection);
            }
    
            setAlertSuccess(true);
            console.log("Files saved successfully.");
        } catch (error) {
            console.error("Error saving files:", error);
        } finally {
            setLoading(false);
            hideModal();
        }
    };

    const getFileIcon = (fileType) => {
        if (fileType?.includes('pdf')) {
            return '📄';
        } else if (fileType?.includes('image')) {
            return '🖼️';
        }
        return '📎';
    };

    return (
        <div>
            <div  onClick={() => setShowModalForm(true)}>
                {certificates?.length > 0 ? HandIndexThumbIcon(18) : PlusCircle2Icon(18)}
            </div>
            <Modal
                show={showModalForm}
                onHide={hideModal}          
                aria-labelledby="example-custom-modal-styling-title"
                size={certificates?.length > 0 ? "xl" : "md"}
                className={c.cert_preview_modal}
            >
                <ModalHeader 
                    title={certificates?.length > 0 ? ('Наявні Документи') : ('Додайте Документ')}
                />

                <Modal.Body>
                    <Container fluid className={c.modal__form}>
                        {certificates?.length > 0 && (
                            <Row className="mb-4">
                                {certificates.map((cert, index) => (
                                    <Col key={index} xs={12} md={6} lg={4} className="mb-3">
                                        <div className={`${c.cert_preview_card} p-2 border rounded`}>
                                            <div className="d-flex justify-content-between align-items-start mb-2">
                                                <span className="cert-icon me-2">
                                                    {getFileIcon(cert.fileType)}
                                                </span>
                                            </div>
                                            
                                            {cert.fileType?.includes('image') ? (
                                                <div className={`${c.cert_preview_image} mb-2`}>
                                                    <img 
                                                        src={cert.downloadURL} 
                                                        alt='img'
                                                        className="img-fluid rounded"
                                                        style={{ maxHeight: '200px', width: '100%', objectFit: 'cover' }}
                                                        onClick={() => window.open(cert.downloadURL, '_blank')}
                                                    />
                                                </div>
                                            ) : (
                                                <div 
                                                    className={`${c.cert_preview_pdf} mb-2 text-center p-3 bg-light rounded`}
                                                    onClick={() => window.open(cert.downloadURL, '_blank')}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <span style={{ fontSize: '2rem' }}>📄</span>
                                                </div>
                                            )}
                                            
                                            <div className={c.cert_info}>
                                                <p className="mb-2 text-truncate" title={cert.fileName}>
                                                    {cert.fileName}
                                                </p>
                                                <Button 
                                                    variant="outline-success"
                                                    size="sm"
                                                    className="w-100"
                                                    onClick={() => window.open(cert.downloadURL, '_blank')}
                                                >
                                                    Відкрити
                                                </Button><br/>
                                                <div className='my-2'>
                                                <DeleteConfirmForm 
                                                  collection={collection}
                                                  docID={cert.id}
                                                  goTo={'/whisky'}
                                                  icon={true}
                                                  iconSize={16}
                                                  colour={'red'}
                                                  idWord2={'документа'}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        )}

                        <div className={certificates?.length > 0 ? "border-top pt-4" : ""}>
                            <ImgPickerMultiple 
                                setFilesData={handleFileAdd} 
                                setLoading={setLoading} 
                                onFileUrl={handleFileUrl}
                                docId={doc.id}
                            />

                            {selectedFiles.length > 0 && (
                                <ul className="mt-3">
                                    {selectedFiles.map((file, index) => (
                                        <li key={index}>{file.name}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                   
                        <>               
                            <Button onClick={hideModal} variant="secondary">Скасувати</Button>
                            <Button 
                                variant="success" 
                                onClick={handleSave} 
                                disabled={loading || selectedFiles.length === 0}
                            >
                                {loading ? 'Збереження...' : 'Зберегти дані'}
                            </Button>
                        </>
                 
                </Modal.Footer>
            </Modal>  
        </div>
    );
};

export default AddCert;
