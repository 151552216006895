import _ from "lodash";
import firebase from '../firebase';
import { nanoid } from '@reduxjs/toolkit';



export const getCar = (data, arg) => {
   let result = data.filter(function(doc) {
      return doc.numPlate === arg; 
   });
   return result;
};
export const getMonth = (data, arg) => {
   let result = data.filter(function(doc) {
      return `${doc.dateMonth} ${doc.dateYear}` === arg; 
   });
   return result;
};
export const getCarMonthType = (data, arg) => {
   let result = data.filter(function(doc) {
      return doc.type === arg; 
   });
   return result;
};

export const getCarArr = (data) => {
   let carAcc = [];
   data.map((doc) => carAcc.push(`${doc.numPlate}`));
     const uniqueArr = [...new Set(carAcc)];
     return uniqueArr;
};
///////////////////////////////////////// CAR
export const getCarArrCAR = (data) => {
   let carAcc = [];
   data.map((doc) => carAcc.push(`${doc.car}`));
     const uniqueArr = [...new Set(carAcc)];
     return uniqueArr;
};
//////////////////////////////////////////// doc.car
export const getCarCAR = (data, arg) => {
   let result = data.filter(function(doc) {
      return doc.car === arg; 
   });
   return result;
};
////////////////////////////////////////////
export const getDateArr = (data) => {
   let dateAcc = [];
   data.map((doc) => dateAcc.push(`${doc.dateMonth} ${doc.dateYear}`));
     const uniqueArr = [...new Set(dateAcc)];
     return uniqueArr;
};
export const getTypeArr = (data) => {
   let dateAcc = [];
   data.map((doc) => dateAcc.push(`${doc.type}`));
     const typeArr = [...new Set(dateAcc)];
     return typeArr;
};
export const getRides = (rides, arg) => {
    let data = rides.filter(function(taxi) {
       return taxi.service.toUpperCase() === arg.toUpperCase(); 
    });
    return data;
 };
 export const getElType = (lookIn, arg) => {
   let data = lookIn.filter(function(doc) {
      return doc.elType === arg; 
   });
   return data;
};
export const getType = (lookIn, arg) => {
   let data = lookIn.filter(function(doc) {
      return doc.type === arg; 
   });
   return data;
};
export const getTipped = (lookIn) => {
   let data = lookIn.filter(function(doc) {
      return doc.tips !== "" && doc.tips !== 0; 
   }); ///////////////////////////////////////// Выбирает поездки с чаевыми. Формирует массив объектов.
   return data;
};
export const calculateTipsSum = arg => {
   let result = _.sum(
      arg.map(item => ( +item.tips )) );/////////////////////// Суммировать св-во sum в массиве выбранных объектов 
  return result;
};
 
export const calculateSum = arg => {
   let result = _.sum(
      arg.map(item => ( +item.sum )) );/////////////////////// Суммировать св-во sum в массиве выбранных объектов 
  return result;
};
export const calculateRange = arg => {
    let result = _.sum(
       arg.map(item => ( +item.range )) );/////////////////////// Дистанция поездки
   return result;
 };
export const calculateCost = arg => {
    let result = _.sum(
       arg.map(item => ( +item.cost ))); ////////////////////// Стоимость поездки
    return result;
 };
export const calculatePercent = arg => {
    let result = _.sum(
       arg.map((trip) => (+trip.cost * trip.percent / 100 ))); ////////////////////// Процент оператора
    return result;
 };
export const calculateServiceCost = arg => {
    let result = _.sum(
       arg.map(item => (+item.serviceCost))); ////////////////////// Сумму ежедневной абонплаты оператора
   return result;
 };
export const calculateCashLessPay = arg => {
    let result = _.sum(
       arg.map(item => ( +item.cashLessPay))); ////////////////////// Сумму без.нал платежей
    return result;
 };
export const calculateTips = arg => {
    let result = _.sum(
       arg.map(item => (+item.tips )));  ////////////////////// Сумму без.нал чаевых   
   return result;
 };

 // Можно переработать логику этого компонента
/////////////////////////////////////////////////
// const rides = rides.filter(function(taxi) {
//    return taxi.service === ''; 
// });
// export const findCarID = (items, car) => {
//    let data = items.filter(function(item, car) {
//       return item.numPlate === car; });        
//    return data;
// };

export const excludeCommons = (items) => {
   let data = items.filter(function(item) {
      return item.car !== "АВТОПАРК"; });        
   return data;
};
export const excludeLosses = (items) => {
   let data = items.filter(function(item) {
      return item.type !== "losses"; });        
   return data;
};

export const getSpends = (spends, arg) => {
    let data = spends.filter(function(spend) {
       return spend.type === arg; });        
    return data;
 };
 export const calculateData = arg => {
    let result = _.sum(
       arg.map(item => ( +item.sum )));
   return result;
 };
 export const calculateDubleData = arg => {
   let result = _.sum(
      arg.map(item => ( +item.sum * +item.quantity )));
  return result;
};

export const addItem = (item, ref) => {  
    ref.add(item)                         /////////////////////
       .catch((err) => {
         console.log(err);
         //setError(true);
       });
 };

 export const addItemNoRef = (item, collection) => {  
   firebase.firestore().collection(collection)
      .add(item)                         /////////////////////
      .catch((err) => {
        console.log(err);       
      });
};
export const editItemNoRef = (data, collection, docID) => {
   firebase.firestore().collection(collection)
   .doc(docID)
   .update(data);
   };

export const delItemNoRef = (collection, docID) => {
   firebase.firestore().collection(collection)
   .doc(docID)
   .delete();
};

export const addItemWithTrigers = (item, collection, setAddingItem, setDoneAdding, setError) => {
   setAddingItem(true);
   firebase.firestore().collection(collection)
   .add(item)                         
   .then(() => {
      setAddingItem(false);
      setDoneAdding(true);
     })                        
   .catch(() => {
      setAddingItem(false);
      setError(true);       
     });  
};
export const editItemWithTrigers = (data, collection, docID, setAddingItem, setDoneAdding, setError) => {
   setAddingItem(true);
   firebase.firestore().collection(collection)
   .doc(docID)
   .update(data)
   .then(() => {
      setAddingItem(false);
      setDoneAdding(true);
     })                        
   .catch(() => {
      setAddingItem(false);
      setError(true);       
     });  
   };



/// Використ. в комп CarRent
export const choosePayDayStringUkr = ( payment ) => {   
      if (payment.payDay === 'Mon') {
            return 'Понеділок';
      } else if (payment.payDay === 'Tue') {
            return 'Вівторок';
      } else if (payment.payDay === 'Wed') {
            return 'Середа';
      } else if (payment.payDay === 'Thu') {
            return 'Четвер';
      } else if (payment.payDay === 'Fri') {
            return `П'ятниця`;
      } else if (payment.payDay === 'Sat') {
            return 'Субота';
      } else if (payment.payDay === 'Sun') {
            return 'Неділя';
      } else if (payment.payDay === 'None') {
         return 'Не Встановлено';
   }
};

export const plusOneMonth = (month, year) => {
 if (month === 'Jan') {
   return `Feb 01 ${year}`; 
 } else if (month === 'Feb') {
   return `Mar 01 ${year}`;
 } else if (month === 'Mar') {
   return `Apr 01 ${year}`;
 } else if (month === 'Apr') {
   return `May 01 ${year}`;
 } else if (month === 'May') {
   return `Jun 01 ${year}`;
 } else if (month === 'Jun') {
   return `Jul 01 ${year}`;
 } else if (month === 'Jul') {
   return `Aug 01 ${year}`;
 } else if (month === 'Aug') {
   return `Sep 01 ${year}`;
 } else if (month === 'Sep') {
   return `Oct 01 ${year}`;
 } else if (month === 'Oct') {
   return `Nov 01 ${year}`;
 } else if (month === 'Nov') {
   return `Dec 01 ${year}`;
 } else if (month === 'Dec') {
   return `Jan 01 ${+year + 1}`;
 }
};

export const getYearMonthRecords = (arr, month, year) => {
 let data = arr.filter(item => item.dateMonth === month && item.dateYear === year);
 let result = _.sum(
   data.map(item => ( +item.sum )));
return result;
};

export const splitYear = (arg) => {
   return +arg.slice(arg.length - 4, arg.length);
};
export const splitMonth = (arg) => {
   return arg.slice(0, arg.length - 5);
};

export const getYearMonthRecords2 = (arr, month1, month2) => {
   let data1 = arr.filter(item => item.dateMonth === month1);
   let data2 = arr.filter(item => item.dateMonth === month2);
   let data = [];
   data.push(...data1, ...data2);
   let result = _.sum(
     data.map(item => ( +item.sum )));
  return result;
  };

  export const getYearlyIncoms = (incomsArr) => {
   //let data1 = arr.filter(item => item.dateMonth === month1);
   //let data2 = arr.filter(item => item.dateMonth === month2);
   let yarlyIncoms = +getYearMonthRecords2(incomsArr, 'Сiчня', 'Января') + +getYearMonthRecords2(incomsArr, 'Лютого', 'Февраля') + 
             +getYearMonthRecords2(incomsArr, 'Березня', 'Марта') + +getYearMonthRecords2(incomsArr, 'Квiтня', 'Апреля') +
             +getYearMonthRecords2(incomsArr, 'Травня', 'Мая') + getYearMonthRecords2(incomsArr, 'Червня', 'Июня') +
             +getYearMonthRecords2(incomsArr, 'Липня', 'Июля') + getYearMonthRecords2(incomsArr, 'Серпня', 'Августа') +
             +getYearMonthRecords2(incomsArr, 'Вересня', 'Сентября') + getYearMonthRecords2(incomsArr, 'Жовтня', 'Октября') +
             +getYearMonthRecords2(incomsArr, 'Листопада', 'Ноября') + getYearMonthRecords2(incomsArr, 'Грудня', 'Декабря');
      
   return yarlyIncoms;
  };

  //const data = commonSpendings.find(p => p.id === match.params.id);
  export const getUSDRate = (arr, month, year) => {
   //let data = arr.filter(item => item.dateMonth === month && item.dateYear === year);
  let result = arr?.filter(i => i.dateYear === year && i.dateMonth === month);
  let data = result[0]?.sum;
  return data;
  };
  export const getCarRecordID = (arr, car) => {
   //let data = arr.filter(item => item.dateMonth === month && item.dateYear === year);
  let data = arr?.find(i => i.numPlate === car);
  //let data = result?.id;
   return data
  };

  export const getOffHireItemID = (arr, car) => {
   //let data = arr.filter(item => item.dateMonth === month && item.dateYear === year);
  let result = arr?.filter(i => i.car === car && i.status === 'offHire');
  let data = result[0]?.id;
  return data;
  //return result;
  };

export const getMonthPaidSum = (arr, year, month) => {
   let data = arr?.filter(i => i.paidYear === year && i.paidMonth === month);
       let result = data[0]?.sum;  
 return result;
};

export function setCookie(name, value, days = 14) {
   const expires = new Date(Date.now() + days * 864e5).toUTCString();
   document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/; Secure; SameSite=Strict`;
 }

 
 // Utility function to get a cookie by name
 export function getCookie(name) {
   return document.cookie.split('; ').reduce((r, v) => {
     const parts = v.split('=');
     return parts[0] === name ? decodeURIComponent(parts[1]) : r;
   }, '');
 }
 
 // Utility function to delete a cookie by name
 export function deleteCookie(name) {
   setCookie(name, '', -1); // Set the cookie with an expiration date in the past
 }


 export function generatePwd(setPassword) {
   const randomPwd = nanoid().slice(0, 16)
   if(/[a-z]/.test(randomPwd) && /[A-Z]/.test(randomPwd) && /[0-9]/.test(randomPwd)) {
     setPassword(randomPwd)    
   } else {generatePwd(setPassword)}    
 }
 
///////////////////////////// Сортируем массив трат
    //  const getSpends = arg => {
    //     let data = spends.filter(function(spend) {
    //        return spend.type === arg; });        
    //     return data;
    //  };
    ///////////////////////////// Получаем суммы трат
    // const calculateData = arg => {
    //     let result = _.sum(
    //        arg.map(item => ( +item.sum )));
    //    return result;
    //  };










 //////////////////////////////////// Обявим ф-ии счетчики
//  const calculateRange = arg => {
//    let result = _.sum(
//       arg.map(item => ( +item.range )) );/////////////////////// Дистанция поездки
//   return result;
// };
// const calculateCost = arg => {
//    let result = _.sum(
//       arg.map(item => ( +item.cost ))); ////////////////////// Стоимость поездки
//    return result;
// };
// const calculatePercent = arg => {
//    let result = _.sum(
//       arg.map((trip) => (+trip.cost * trip.percent / 100 ))); ////////////////////// Процент оператора
//    return result;
// };
// const calculateServiceCost = arg => {
//    let result = _.sum(
//       arg.map(item => (+item.serviceCost))); ////////////////////// Сумму ежедневной абонплаты оператора
//   return result;
// };
// const calculateCashLessPay = arg => {
//    let result = _.sum(
//       arg.map(item => ( +item.cashLessPay))); ////////////////////// Сумму без.нал платежей
//    return result;
// };
// const calculateTips = arg => {
//    let result = _.sum(
//       arg.map(item => (+item.tips )));  ////////////////////// Сумму без.нал чаевых   
//   return result;
// };


// const getRides = arg => {
//    let data = rides.filter(function(taxi) {
//       return taxi.service === arg; 
//    });
//    return data;
// }; Оригинальная версия

//  function addNewRide(ride, ridesRef) {
//      ridesRef
//        // .doc(ride.id)
//        // .set(ride)
//         .add(ride)
//         .catch((err) => {
//             console.log(err);
//         });
//  }

//  function addNewSpend(spend) {
//   spendRef
//      .add(spend)
//      .catch((err) => {
//          console.log(err);
//      });
// }
// function addNewParts(parts) {
//   partsRef
//      .add(parts)
//      .catch((err) => {
//          console.log(err);
//      });
// }
// function addNewService(service) {
//   serviceRef
//      .add(service)
//      .catch((err) => {
//          console.log(err);
//      });
// }
// function addNewOther(other) {
//   otherRef
//      .add(other)
//      .catch((err) => {
//          console.log(err);
//      });
// }



////////////////////////////////////////////////////////////////
// Filtering an array of objects

// A common use case of .filter() is with an array of objects through their properties:
// var heroes = [
//    {name: “Batman”, franchise: “DC”},
//    {name: “Ironman”, franchise: “Marvel”},
//    {name: “Thor”, franchise: “Marvel”},
//    {name: “Superman”, franchise: “DC”}
// ];

// var marvelHeroes =  heroes.filter(function(hero) {
//    return hero.franchise == “Marvel”;
// });

// [ {name: “Ironman”, franchise: “Marvel”}, {name: “Thor”, franchise: “Marvel”} ]



/////////////////////////////////////////////////////////////////
//https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Array/filter
// Следующий пример использует filter() для создания отфильтрованного массива, все элементы которого больше или равны 10, а все меньшие 10 удалены.
// var arr = [
//    { id: 15 },
//    { id: -1 },
//    { id: 0 },
//    { id: 3 },
//    { id: 12.2 },
//    { },
//    { id: null },
//    { id: NaN },
//    { id: 'undefined' }
// ];

// var invalidEntries = 0;

// function isNumber(obj) {
//    return obj!== undefined && typeof(obj) === 'number' && !isNaN(obj);
// }

// function filterByID(item) {
//    if (isNumber(item.id) && item.id !== 0) {
//        return true;
//    }
//    invalidEntries++;
//    return false;
// }

// var arrByID = arr.filter(filterByID);

// console.log('Отфильтрованный массив\n', arrByID);
// // Filtered Array
// // [{ id: 15 }, { id: -1 }, { id: 3 }, { id: 12.2 }]

// console.log('Количество ошибочных записей = ', invalidEntries);
// // Number of Invalid Entries = 5

