import { useState, useEffect } from 'react';
import firebase from "../firebase";

const useSubcollection = (path) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const unsub = firebase.firestore().collection(path)
      .onSnapshot((snap) => {
        let docs = [];
        snap.forEach(doc => {
          docs.push({id: doc.id, ...doc.data()});
        });
        setDocuments(docs);
        setLoading(false);
      });

    return () => unsub();
  }, [path]);

  return { documents, loading };
};

export default useSubcollection; 