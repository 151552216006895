import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal, 
} from 'react-bootstrap';
import ModalHeader from '../Components/ModalHeader';
import FooterButtons from '../Components/FooterButtons';

const CascTypes = () => {

    const [showModalForm, setShowModalForm] = useState(false);
    const hideModal = () => {          
        setShowModalForm(false);    
      };

  return (
    <>
        <div className={`mb-3`}>
          <Button 
           variant={'outline-light'}          
           onClick={() => setShowModalForm(true)}
          >
          Про Діжки
         
          </Button>
        </div>

        <Modal
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          >
          <ModalHeader 
            title={`Definitions`}
           />          
           <Modal.Body>          
             <Container className={c.modal__form2}>
           

                <span className={c.defenition}>AYS:</span> Age of Youngest Spirit, therefore the age of the cask<br/>

                <span className={c.defenition}>Barrel:</span> Cask holding approximately 130 LOA<br/>

                <span className={c.defenition}>HHD:</span> Hogshead cask, cask holding approximately 160-180 LOA<br/>

                <span className={c.defenition}>Barrique:</span> cask holding approximately 160-180 LOA<br/>

                <span className={c.defenition}>Butt:</span> Cask holding up to approximately 317 LOA<br/>

                <span className={c.defenition}>Puncheon:</span> Cask holding up to approximately 317 LOA<br/>

                <span className={c.defenition}>OLA:</span> Original Litres of Alcohol in the cask when first filled, 1-2% of the spirit will be lost to evaporation (the angel’s share) every year<br/>

                <span className={c.defenition}>RLA:</span> A re-gauged measurement of volume, accounting for the angels’ share<br/>

                <span className={c.defenition}>LOA:</span> Litres of pure alcohol in the cask<br/>

                <span className={c.defenition}>ABV:</span> Alcohol by volume<br/>
                
             </Container>
           </Modal.Body>
            <FooterButtons 
              onCancel={hideModal}            
              text1={'Закрити вікно'}
              text2={false}
              disabled={true}
            />          
         </Modal>
        </>
  )
}

export default CascTypes