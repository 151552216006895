import { useState, useEffect } from 'react';
import { projectStorage } from "../firebase";

const useCertStorage = (file, docId) => {
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        if (!file || !docId) return;

        const storageRef = projectStorage.ref(`whisky-certificates/${docId}/${file.name}`);

        storageRef.put(file).on('state_changed', 
            (snap) => {
                const percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
                setProgress(percentage);
            }, 
            (err) => {
                setError(err);
            }, 
            async () => {
                const url = await storageRef.getDownloadURL();
                setUrl(url);
            }
        );
    }, [file, docId]);

    return { progress, url, error };
};

export default useCertStorage; 