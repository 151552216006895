import React, { useEffect } from 'react';
import { ProgressBar } from "react-bootstrap";
import useCertStorage from "../Hooks/useCertStorage";

const ProgBarMulty = ({ file, setFiles, setLoading, onUploadComplete, docId }) => {
    const { url, progress } = useCertStorage(file, docId);

    useEffect(() => {
        if (url) {
            setFiles((prevFiles) => prevFiles.filter((f) => f.name !== file.name));
            onUploadComplete(file, url);
            setLoading(false);
        }
    }, [url, setFiles, file, setLoading, onUploadComplete]);

    return (
        <div>
           <ProgressBar 
             animated 
             now={progress} 
             label={`${progress.toFixed(0)}%`}
             variant="info" 
           /> 
        </div>
    );
};

export default ProgBarMulty;
